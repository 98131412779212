import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const MainArticleContainer = styled(Link)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 30px;
  border-bottom: 1px solid #c4c4c4;
  margin: 30px 0 0 0;
  text-decoration: none;

  @media (max-width: 700px) {
    margin: 20px 0 0 0;
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`

const MainArticleImg = styled.img`
  width: 100%;
  background-color: #c4c4c4;
  margin: 0 0 30px 0;

  @media (max-width: 700px) {
    margin: 0 0 20px 0;
  }
`

const MainArticleTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 0 30px 0;

  @media (max-width: 700px) {
    width: 100%;
    margin: 0 0 20px 0;
  }
`

const MainArticleTitle = styled.h4`
  font-size: 24px;
  font-weight: 400;
  font-family: "MinionPro";
  color: black;
  margin: 0 0 4px 0;
`

const MainArticleCredit = styled.p`
  font-size: 14px;
  color: #294973;
  font-family: "franklinGothic";
  margin: 0 0 20px 0;
`

const MainArticleExcerpt = styled.p`
  font-size: 16px;
  color: black;
  font-family: "franklinGothic";
  margin: 0 0 10px 0;
`

const MainArticleDate = styled.p`
  font-size: 14px;
  color: #294973;
  font-family: "franklinGothic";
  margin: 0;
`

const MainArticle = ({
  title,
  date,
  author,
  featured_image,
  excerpt,
  slug,
}) => {
  const [articleDate, setArticleDate] = useState("")
  useEffect(() => {
    let dateString = date
    let arr_date = dateString.slice(0, 10).split("-"),
      months = [
        "",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ]
    setArticleDate(
      months[parseInt(arr_date[1])] + " " + arr_date[2] + ", " + arr_date[0]
    )
  })
  return (
    <MainArticleContainer to={`/articles/${slug}`}>
      <MainArticleImg src={featured_image.source_url}></MainArticleImg>
      <MainArticleTextWrapper>
        <MainArticleTitle id={`mainArticleTitle-${slug}`}>
          {title}
        </MainArticleTitle>
        <MainArticleCredit>By {author}</MainArticleCredit>
        <MainArticleExcerpt id={`mainArticleExcerpt-${slug}`}>
          {excerpt.substring(0, excerpt.length - 18)}...
        </MainArticleExcerpt>
        <MainArticleDate>{articleDate}</MainArticleDate>
      </MainArticleTextWrapper>
    </MainArticleContainer>
  )
}

export default MainArticle
