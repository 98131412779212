import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Article from "../components/article"
import MainArticle from "../components/mainArticle"

const Content = styled.div`
  width: calc(100% - 230px);
  display: flex;
  flex-direction: column;
  margin: 60px 0 0 0;
  border-right: 1px solid #c4c4c4;

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    margin: 30px 0 0 0;
    border-right: none;
  }
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #c4c4c4;
`

const Title = styled.h1`
  font-size: 48px;
  color: black;
  font-family: "MinionPro";
  font-weight: 400;
  margin: 0 0 20px 0;

  @media (max-width: 700px) {
    font-size: 36px;
  }
`

const Description = styled.p`
  width: 600px;
  font-size: 16px;
  font-family: "franklinGothic";
  margin: 0 0 30px 0;
  color: black;

  @media (max-width: 1000px) {
    width: calc(100% - 30px);
  }

  @media (max-width: 700px) {
    width: calc(100% - 15px);
    margin: 0 0 20px 0;
  }
`

const SeeMoreButton = styled.button`
  cursor: pointer;
  width: 150px;
  height: 45px;
  display: ${props => (props.moreArticlesActive ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  border: 1px solid #294973;
  font-size: 14px;
  color: black;
  font-family: "franklinGothic";
  margin: 30px 0 0 0;
  background-color: white;
`

const Section = ({ data, sectionName, sectionDescription }) => {
  const [matchingArticles, setMatchingArticles] = useState([])
  const [moreArticlesActive, setMoreArticlesActive] = useState(false)
  const [mainArticle, setMainArticle] = useState()

  const showMoreArticles = () => {
    setMoreArticlesActive(true)
  }

  useEffect(() => {
    setMatchingArticles(matchingArticles => [...data])
    let tempDiv = document.createElement("DIV")
    let tempDiv2 = document.createElement("DIV")

    const foundMainArticle = data.find(post => {
      if (post.node.featured_media) {
        tempDiv.innerHTML = post.node.title
        post.node.title = tempDiv.innerText
        tempDiv2.innerHTML = post.node.excerpt
        post.node.excerpt = tempDiv2.innerText
        return post
      }
    })

    if (foundMainArticle) {
      setMainArticle(foundMainArticle)
    }
  }, [])

  const articles = matchingArticles.slice(0, 3).map((article, i) => {
    const post = article.node
    if (post.title === mainArticle.node.title) {
      return <></>
    }
    return (
      <Article
        title={post.title}
        slug={post.slug}
        author={post.author.name}
        date={post.date}
        featured_image={post.featured_media}
        excerpt={post.excerpt}
        moreArticlesActive={true}
        key={`key-${i}-${post.slug}`}
      />
    )
  })

  const extraArticles = matchingArticles.slice(3).map((article, i) => {
    const post = article.node
    return (
      <Article
        title={post.title}
        slug={post.slug}
        author={post.author.name}
        date={post.date}
        featured_image={post.featured_media}
        excerpt={post.excerpt}
        moreArticlesActive={moreArticlesActive}
        key={`key-${i}-${post.slug}`}
      />
    )
  })
  return (
    <>
      <Content>
        <TitleContainer>
          <Title>{sectionName}</Title>
          <Description>{sectionDescription}</Description>
        </TitleContainer>
        {mainArticle ? (
          <MainArticle
            title={mainArticle.node.title}
            slug={mainArticle.node.slug}
            author={mainArticle.node.author.name}
            date={mainArticle.node.date}
            featured_image={mainArticle.node.featured_media}
            excerpt={mainArticle.node.excerpt}
          />
        ) : (
          <></>
        )}
        {articles}
        {extraArticles}
        <SeeMoreButton
          moreArticlesActive={moreArticlesActive}
          onClick={showMoreArticles}
        >
          See more
        </SeeMoreButton>
      </Content>
    </>
  )
}

export default Section
